<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="900px">
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<template #modal-content>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							v-model="cassieModule"
							:disabled="!userFullPermissions || isEdit || containsClaim"
							label="Cassie Module"
							test-id="sso-configuration-cassie-module-dropdown"
							tooltip-text="Select the Cassie Module which you are mapping to the SSO Login"
							:items="cassieModuleItems"
						/>
					</v-col>
					<v-col cols="6">
						<TextField
							v-model="groupName"
							:disabled="!userFullPermissions || isEdit || containsClaim"
							test-id="sso-configuration-group-name"
							tooltip-text="The name of a group or role within the SSO provider to map against the Cassie module"
							:label="selectedProvider.claimMappingDisplayName"
						/>
					</v-col>
				</v-row>
				<v-row class="my-1">
					<v-col cols="12">
						<v-divider />
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							v-model="cassieComponent"
							:disabled="!userFullPermissions"
							:label="cassieComponentDropdownLabel"
							test-id="sso-configuration-cassie-component-dropdown"
							tooltip-text="Select the specific Cassie component you are looking to map from the module selected"
							:items="getComponentItems"
						/>
					</v-col>
					<v-col cols="6">
						<TextField
							v-model="claimName"
							:disabled="!userFullPermissions"
							:rules="{ max: 255 }"
							test-id="sso-configuration-claim-name"
							tooltip-text="The attribute in the SSO provider mapping against the Cassie component"
							:label="selectedProvider.claimAttributeDisplayName"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-spacer />
					<v-col
						cols="4"
						class="text-right"
					>
						<PrimaryActionButton
							v-if="userFullPermissions"
							:disabled="!cassieModule || !groupName || (!cassieComponent && cassieComponent !== 0) || !claimName"
							@click="handleSubmit(addClaim)"
						>
							Add
						</PrimaryActionButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<DataTable
							:headers="tableHeaders"
							:items="ssoMapping"
						>
							<template #item.cassieModuleName="{ item }">
								{{ getComponentTypeName(item.componentTypeId) }}
							</template>
							<template #item.order="{ index }">
								{{ index + 1 }}
							</template>
							<template #item.action="{item, index}">
								<IconButton
									v-if="index !== 0 && userFullPermissions"
									@click="moveUp(index, item)"
								>
									mdi-arrow-up
								</IconButton>
								<IconButton
									v-if="index !== ssoMapping.length - 1 && userFullPermissions"
									@click="moveDown(index, item)"
								>
									mdi-arrow-down
								</IconButton>
								<IconButton
									v-if="userFullPermissions"
									@click="removeClaim(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</v-col>
				</v-row>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton @click="$emit('close-modal')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="handleSubmit(updateClaims)"
				>
					Add
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { brandOptions } from '../../../../../../shared/state/brands.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
export default {
	components: {
		ValidationForm,
		Modal,
		Dropdown,
		TextField,
		DataTable,
		PrimaryActionButton,
		IconButton,
		SecondaryActionButton
	},
	props: {
		ssoConfiguration: Object,
		userFullPermissions: Boolean,
		lookupData: Object,
		ssoMappingToManage: Array,
		productRoles: Array
	},
	setup () {
		return {
			brandOptions,
			showSnackbar
		}
	},
	data () {
		const USER_ROLES = 1
		const BRANDS = 2
		return {
			ssoMapping: [],
			cassieModule: null,
			groupName: null,
			cassieComponent: null,
			claimName: null,
			USER_ROLES,
			BRANDS
		}
	},
	computed: {
		modalTitle () {
			return `${this.isEdit ? 'Edit' : 'Add'} SSO Mapping`
		},
		isEdit () {
			return !!this.ssoMappingToManage?.length
		},
		containsClaim () {
			return this.ssoMapping.some(mapping => mapping.componentTypeId === this.cassieModule)
		},
		selectedProvider () {
			return this.lookupData.ssoProviders.find(provider => provider.id === this.ssoConfiguration.ssoProviderId)
		},
		cassieModuleItems () {
			const moduleTypes = this.lookupData.ssoComponentTypes.map(module => ({
				text: module.componentTypeName,
				value: module.id
			}))
			return this.isEdit ? moduleTypes : moduleTypes.filter(({ text }) => !this.ssoConfiguration.mappedClaims.some(claim => claim.cassieModuleName === text))
		},
		tableHeaders () {
			return [
				{
					text: 'Cassie Module',
					value: 'cassieModuleName'
				},
				{
					text: this.selectedProvider.claimMappingDisplayName,
					value: 'componentTypeExternalName'
				},
				{
					text: 'Cassie Component',
					value: 'cassieComponentName'
				},
				{
					text: this.selectedProvider.claimAttributeDisplayName,
					value: 'externalComponentName'
				},
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Action',
					value: 'action',
					align: 'right'
				}
			]
		},
		getComponentItems () {
			switch (this.cassieModule) {
				case this.USER_ROLES:
					return this.productRoles.map(role => ({
						text: role.name,
						value: role.roleId
					})).filter(role => !this.ssoMapping.some(mapping => mapping.cassieComponentId === role.value))
				case this.BRANDS:
					return brandOptions.value.filter(brand => !this.ssoMapping.some(mapping => mapping.cassieComponentId === brand.value))
				default:
					return []
			}
		},
		cassieComponentDropdownLabel () {
			switch (this.cassieModule) {
				case this.USER_ROLES:
					return 'Cassie User Role'
				case this.BRANDS:
					return 'Cassie Brand'
				default:
					return 'Cassie Component'
			}
		}
	},
	created () {
		if (this.isEdit) {
			this.ssoMapping = [
				...this.ssoMappingToManage
			]
			this.cassieModule = this.ssoMapping[0].componentTypeId
			this.groupName = this.ssoMapping[0].componentTypeExternalName
		}
	},
	methods: {
		getComponentTypeName (componentTypeId) {
			return this.lookupData.ssoComponentTypes.find(module => module.id === componentTypeId).componentTypeName
		},
		moveDown (index) {
			this.move(this.ssoMapping, index, index + 1)
		},
		moveUp (index) {
			this.move(this.ssoMapping, index, index - 1)
		},
		applySort (array) {
			this.ssoMapping = array
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort(arrayCopy)
		},
		removeClaim (claim) {
			this.ssoMapping = this.ssoMapping.filter(mapping => mapping !== claim).map((mapping, index) => ({
				...mapping,
				displayOrder: index + 1
			}))
		},
		addClaim () {
			const claim = {
				cassieComponentId: this.cassieComponent,
				cassieComponentName: this.getComponentItems.find(item => item.value === this.cassieComponent).text,
				componentTypeExternalName: this.groupName,
				componentTypeId: this.cassieModule,
				displayOrder: this.ssoMapping.length + 1,
				externalComponentName: this.claimName,
				ssoClaimId: 0
			}
			this.ssoMapping.push(claim)
			this.cassieComponent = null
			this.claimName = null
		},
		updateClaims () {
			if (!this.ssoMapping.length) {
				showSnackbar({ text: 'Please add at least one claim', color: 'red' })
				return
			}
			const ssoMapping = this.ssoMapping.map((mapping, index) => ({
				...mapping,
				displayOrder: index + 1
			}))
			this.$emit('update-sso-mapping', ssoMapping)
		}
	}
}
</script>
